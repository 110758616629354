import { Component } from '@angular/core';
import { MENU_ITEMS } from 'src/app/main/shared/constants/menu-items.consts';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent {
  model: any[] = MENU_ITEMS;

  constructor() {}

  getFirstBottom() {
    return this.model.findIndex((m) => m.isBottomOfMenu);
  }
}
