import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectUser, AuthState } from 'src/app/core/store/auth';
import { AuthService } from 'src/app/core/services/auth.service';
import { LayoutService } from '../../service/app.layout.service';

@Component({
  selector: 'app-profilemenu',
  templateUrl: './app.profilesidebar.component.html',
})
export class AppProfileSidebarComponent {
  private readonly user$ = this.store.select(selectUser);
  nameUser: string = '';
  companyName: string = '';
  level?: number = 0;

  constructor(
    public layoutService: LayoutService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly store: Store<{ auth: AuthState }>
  ) {
    this.user$.subscribe((user) => {
      this.nameUser = `${user?.name} ${user?.surname}`;
      this.companyName = user?.company.name!;
      this.level = user?.company.level;
    });
  }

  get visible(): boolean {
    return this.layoutService.state.profileSidebarVisible;
  }

  set visible(_val: boolean) {
    this.layoutService.state.profileSidebarVisible = _val;
  }

  signOut() {
    this.authService.logout();
    this.router.navigate(['/public']);
  }
}
