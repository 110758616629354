<div class="layout-container" [ngClass]="containerClass">
  <aside class="layout-content-wrapper">
    <app-sidebar></app-sidebar>
  </aside>
  <div class="main-content">
    <app-main-topbar></app-main-topbar>
    <div class="h-full w-full overflow-y-scroll">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-profilemenu></app-profilemenu>
  <app-config></app-config>
  <div class="layout-mask" (click)="onMaskClick()"></div>
</div>
